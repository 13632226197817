<template>
  <div class="search">
    <div style="display: flex; flex-direction: column; align-items: center;
      padding-top: 80px;">
      <img src="@/assets/images/coupon/coupon.png" style="width: 80px;height: 80px;"  alt=""/>
      <div style="font-size: 16px;color: #000000;margin-top: 44px;font-weight: 500;">领取成功</div>
      <div style="font-size: 12px;color: #717888;margin-top: 12px;">发放额度已预增15分钟离场时间，请尽快离场</div>
    </div>
    <div style="
      border-radius: 8px;
      margin: 25px 16px;
      margin-top: 50px;
      padding: 17px 12px;
      color: #333333;
      width: auto;
      background: #FFFFFF;">
      <div style="display: flex;
        justify-content: space-between;
        align-items: center;">
        <div>
          <div style="font-size:14px;
            font-weight: 500;">{{ couponData.name }}</div>
          <div style="font-size:10px;
            color: #777777;
            margin-top: 10px;">{{ couponData.endDate }}</div>
        </div>
        <div style="font-size:12px; color: #FE7134;margin-right: 12px;">
          ¥<span style="font-size:22px; font-weight: 500;">{{ couponData.price }}</span>
        </div>
      </div>
      <div style="background-color: #E6EBF0;height: 1px;margin: 17px auto;"></div>
      <div style="display: flex; align-items: center">
        <div style="background-color: #C3CBD3;width: 4px;height: 4px;border-radius: 4px;"></div>
        <div style="font-size: 12px;color: #000000; font-weight: 500;margin-left: 4px;">{{ couponData.type }}</div>
      </div>
      <div>

      </div>

    </div>
    <div style="margin: 25px 16px;">
      <div style="margin-top:106px;width: 100%;height: 43px;background: #3A8AFF;border-radius: 4px;font-size: 16px;display: flex;align-items: center;justify-content: center;color: #FFFFFF;"
           @click="closePage">
        我知道了
      </div>
    </div>
  </div>
</template>
<script>
import {
  getCloseParkGatePayInner,
  getPayCloseParkInner,
} from "@/api/system";
// import wx from "_weixin-jsapi@1.1.0@weixin-jsapi";
import wx from "weixin-jsapi";

export default {
  data() {
    return {
      licensePlateDoor: false,
      parkinglotId: '',
      parkingGateId: '',
      activeIndex: 0,
      plateColor:'02',
      licensePlateUnit: [],
      couponData:{},
      // 支付来源 0 app 1 pda(路内) 2 web 3 道闸码(路外-车道码) 4 场内码(路外-场内码)
      source: '4',
    }
  },
  components:{
    // Numplate,
    // WechatOrder,
  },
  mounted() {
    this.couponData = JSON.parse(this.$route.query.param);
  },
  methods: {
    // 判断环境
    isWechat() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/micromessenger/i) == 'micromessenger') { // 判断是否在微信浏览器内
        return true;
      } else {
        return false;
      }
    },

    //关闭页面
    closePage() {
      if(this.isWechat()){
        wx.closeWindow()
      }
    },
    getCloseParkGatePay(){
      getCloseParkGatePayInner(this.parkinglotId, this.parkingGateId, this.source)
          .then(res =>{
            if (res && res.code === 30) {
              if(!res.returnObject.source){
                this.$message.warning('未查询到订单信息')
                return
              }else {
                this.$router.push("/payment?parkingOrderIntoId="+res.returnObject.parkingOrderIntoId+"&source="+this.source);
              }
              console.log(res.returnObject)
            }
          })
    },
    getPayClosePark(){
      if(this.licensePlateUnit.join('').trim() === '' || this.licensePlateUnit.join('').trim().length<7){
        this.$message.warning('请填写完整手机号')
        return
      }
      getPayCloseParkInner(this.parkinglotId, this.licensePlateUnit.join(''), this.plateColor, this.source)
          .then(res =>{
            if (res && res.code === 30) {
              if(!res.returnObject.source){
                this.$message.warning('未查询到订单信息')
                return
              }else {
                this.$router.push("/payment?parkingOrderIntoId="+res.returnObject.parkingOrderIntoId+"&source="+this.source);
              }
              console.log(res.returnObject)
            }
          })
    },
    changePlateColor(color){
      this.plateColor = color;
    },
    pickOn(value) {
      this.licensePlateDoor = true;
      if (this.licensePlateUnit.length <= 7) {
        this.licensePlateUnit.push(value)
      }
    },
    delCarNo() {
      this.licensePlateUnit.pop();
    },
    confirm() {
      if(this.licensePlateUnit.length >= 7) {
        console.log("车牌是："+this.licensePlateUnit.join(''));
        this.licensePlateDoor = false;
      }
    },
  }
}
</script>


<style scoped>
.search{
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  background-color: #F7F8FA;
  background-repeat: repeat-x;
  background-size: 100vw;
  /* //display: flex;
  //flex-direction: column;
  //justify-content: center;
  //align-items: center; */
}

input:focus {
  border-bottom: 3px solid #fff;
  transition: all 0.5s;
}
</style>
